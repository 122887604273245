export const getOptionsOnSale = () => {
    return [
        { value: null, text: '请选择销售状态' },
        { value: 'True', text: '正在售' },
        { value: 'False', text: '已下架' }
    ];
}

export const getOptionsHome = () => {
    return [
        { value: null, text: '请选择是否首页显示' },
        { value: 'True', text: '首页显示' },
        { value: 'False', text: '非首页显示' }
    ];
}

export const getOptionsRecommend = () => {
    return [
        { value: null, text: '请选择是否推荐' },
        { value: 'True', text: '推荐' },
        { value: 'False', text: '不推荐' }
    ];
}

export const getOptionsNew = () => {
    return [
        { value: null, text: '请选择是否新产品' },
        { value: 'True', text: '新产品' },
        { value: 'False', text: '非新产品' }
    ];
}

export const getOptionsCategory = () => {
    return [
        { value: null, text: '请选择分类' },
        { value: 1, text: 'PU' },
        { value: 2, text: '真皮' },
        { value: 3, text: '尼龙' },
        { value: 4, text: '其它' }
    ];
}

export const getOptionsSize = () => {
    return [
        { value: null, text: '请选择大小' },
        { value: 1, text: '钱包' },
        { value: 2, text: '小包' },
        { value: 3, text: '中包' },
        { value: 4, text: '大包' }
    ];
}

export const getOptionsSortBy = () => {
    return [
        { value: null, text: '请选择排序列' },
        { value: 'created_time', text: '按创建时间降序' },
        { value: 'product_code', text: '按产品编号降序' }
    ];
}

export const getOptionsStatus = () => {
    return [
        { value: null, text: '请选择状态' },
        { value: 0, text: '待付款' },
        { value: 1, text: '待发货' },
        { value: 2, text: '待收货' },
        { value: 3, text: '已完成' },
        { value: 4, text: '已取消' },
        { value: 5, text: '已删除' }
    ];
}

export const getExpressCompany = () => {
    return [
        { value: null, text: '请选择快递公司' },
        { value: 'sf', text: '顺风快递' },
        { value: 'ems', text: '邮政快递' }
    ];
}

export const getOptionsIsReplied = () => {
    return [
        { value: null, text: '请选择反馈状态' },
        { value: 'True', text: '已回复' },
        { value: 'False', text: '未回复' }
    ];
}