<template>
    <b-container>
        <div style="margin:5px;">
            <b-row>
                <div class="card" style="margin:5px 20px;width:100%">
                    <div class="card-body">
                        <b-form >
                            <b-form-group id="groupSearch" label="" label-for="search">
                                <b-form-input id="search"
                                    v-model="form.search"
                                    placeholder="请输入产品名称">
                                </b-form-input>
                            </b-form-group>
                            <b-form-group id="groupOnSale" label="" label-for="onsale">
                                <b-form-select id="onsale"
                                    v-model="form.sort_sale" 
                                    :options="optionsOnSale"
                                    @change="search">
                                </b-form-select>
                            </b-form-group>
                            <b-form-group id="groupOnHome" label="" label-for="onHome" v-if="showAllFilter == true">
                                <b-form-select id="onHome"
                                    v-model="form.sort_home" 
                                    :options="optionsHome"
                                    @change="search">
                                </b-form-select>
                            </b-form-group>
                            <b-form-group id="groupOnRecommed" label="" label-for="onRecommend" v-if="showAllFilter == true">
                                <b-form-select id="onRecommend"
                                    v-model="form.sort_recommend" 
                                    :options="optionsRecommend"
                                    @change="search">
                                </b-form-select>
                            </b-form-group>
                            <b-form-group id="groupOnNew" label="" label-for="onNew" v-if="showAllFilter == true">
                                <b-form-select id="onNew"
                                    v-model="form.sort_new" 
                                    :options="optionsNew"
                                    @change="search">
                                </b-form-select>
                            </b-form-group>
                            <b-form-group id="groupCategory" label="" label-for="category">
                                <b-form-select id="category"
                                    v-model="form.category" 
                                    :options="optionsCategory"
                                    @change="search">
                                </b-form-select>
                            </b-form-group>
                            <b-form-group id="groupSize" label="" label-for="size">
                                <b-form-select id="size"
                                    v-model="form.size" 
                                    :options="optionsSize"
                                    @change="search">
                                </b-form-select>
                            </b-form-group>
                            <b-form-group id="groupSort" label="" label-for="sort">
                                <b-form-select id="sort"
                                    v-model="form.sort_by" 
                                    :options="optionsSortBy"
                                    @change="search">
                                </b-form-select>
                            </b-form-group>
                            <b-check v-model="showAllFilter">是否显示所有查询条件</b-check>
                            <div style="text-align:right">
                                <b-button type="button" @click="search" variant="primary" style="margin-right:5px;">
                                    查询
                                </b-button>
                                <b-button type="button" @click="onReset" variant="secondary" style="margin:0px 5px;">
                                    重置
                                </b-button>
                                <b-button
                                    v-b-toggle.editor
                                    @click="createProduct"
                                    variant="warning"
                                    style="margin-left:5px;">
                                    新建
                                </b-button>
                            </div>
                        </b-form>
                    </div>
                </div>
            </b-row>    
        </div>

        <div v-if="products.length" 
            style="margin:5px;">
            <b-row class="product-row">
                <div v-for="product in products" 
                    v-bind:key="product.product_code" 
                    style="margin:5px;">
                    <b-col l="4">
                        <b-card v-bind:title="product.product_code"
                            v-bind:img-src="getPictureUrl(product.picture_url)"
                            img-alt="Image"
                            img-top
                            tag="article"
                            style="max-width: 20rem;"
                            class="mb-2">
                            <div class="product-info">
                                <div class="product-name">
                                    名称:&nbsp;{{ product.product_name }}
                                </div>
                                <div style="width:150px;">分类:&nbsp;
                                    {{ getCategoryName(product.category_id) }}
                                    {{ getSizeName(product.size) }}
                                </div>
                            </div>
                            <div class="product-info">
                                <div style="width:150px;">指导价格:&nbsp;{{ product.price }}</div>
                                <div style="width:150px;">实际价格:&nbsp;{{ product.price_sale }}</div>
                            </div>
                            <div class="product-info">
                                <div style="width:150px;">初始销量:&nbsp;{{ product.count_initial_sale }}</div>
                                <div style="width:150px;">实际销量:&nbsp;{{ product.count_sale }}</div>
                            </div>
                            <div class="product-info">
                                <div style="width:150px;" :style="{'color': product.count_store == 0 ? '#F56C6C': ''}">
                                    库存数量:&nbsp;{{ product.count_store }}
                                </div>
                                <div style="width:150px;">点击量:&nbsp;{{ product.click_count == null ? '0' : product.click_count }}</div>
                            </div>
                            <div class="product-info">
                                <div style="width:300px;">尺寸:&nbsp;{{ product.dimension }}</div>
                            </div>
                            <div class="product-info">
                                <div class="product-badgets">
                                    <b-badge variant="success" v-if="product.is_on_sale==true">在售产品</b-badge>
                                    <b-badge variant="danger" v-if="product.is_on_sale==false">下架产品</b-badge>
                                </div>
                                <div class="product-badgets">
                                    <b-badge variant="primary" v-if="product.is_home==true">首页显示</b-badge>
                                    <b-badge variant="secondary" v-if="product.is_home==false">列表显示</b-badge>
                                </div>
                                <div class="product-badgets" v-if="product.is_recommend==true">
                                    <b-badge variant="info" v-if="product.is_recommend==true">推荐产品</b-badge>
                                </div>
                                <div class="product-badgets" v-if="product.is_new==true">
                                    <b-badge variant="warning" v-if="product.is_new==true">新产品</b-badge>
                                </div>
                            </div>
                            <div class="product-info">
                                <b-link href="#"
                                    v-b-toggle.editor
                                    @click="editProduct(product.id, product)"
                                    style="font-size:13px;margin-right:10px;">
                                    编辑
                                </b-link>
                                <b-link href="#"
                                    v-b-modal.modalUploader
                                    @click="showUploaded(product.product_code)"
                                    style="font-size:13px;margin-right:25px;">
                                    上传图片
                                </b-link>
                                <b-link href="#"
                                    @click="deleteProduct(product.product_code)"
                                    style="font-size:13px;color:red;">
                                    删除
                                </b-link>
                            </div>
                            <div class="product-info">
                                <b-link href="#"
                                    @click="toggleProduct('sale', product.product_code, product.is_on_sale)"
                                    style="font-size:13px;margin-right:10px;">
                                    {{ product.is_on_sale == true ? '下架' : '上架' }}
                                </b-link>
                                <b-link href="#"
                                    @click="toggleProduct('home', product.product_code, product.is_home)"
                                    style="font-size:13px;margin-right:10px;">
                                    {{ product.is_home == true ? '设为列表页' : '设为首页' }}
                                </b-link>
                                <b-link href="#"
                                    @click="toggleProduct('recommend', product.product_code, product.is_recommend)"
                                    style="font-size:13px;margin-right:10px;">
                                    {{ product.is_recommend == true ? '设为不推荐' : '设为推荐' }}
                                </b-link>
                                <b-link href="#"
                                    @click="toggleProduct('new', product.product_code, product.is_new)"
                                    style="font-size:13px;">
                                    {{ product.is_new == true ? '设为普通产品' : '设为新产品' }}
                                </b-link>
                            </div>
                        </b-card>
                    </b-col>
                </div>
            </b-row>
            <div style="margin-top:10px;width:90%;">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="total"
                    :per-page="pageSize"
                    align="center">
                </b-pagination>
                <div style="height:30px;line-height:30px;text-align:center;">
                    第 {{ currentPage }} 页
                    总计: {{ total }} 行
                </div>
            </div>
        </div>
        <div v-else style="margin:10px;">
            <h6>没有产品</h6>
        </div>

        <b-sidebar id="editor" 
            :title="editForm.id > 0 ? '编辑产品' : '新建产品'" 
            right shadow
            v-model="showEditor"
            width="80%">
            <div class="px-3 py-2"
                style="margin:5px">
                <b-form @reset="onResetForm" ref="editorForm">
                    <b-form-group id="groupProductCode" label="编号:" label-for="productCode">
                        <b-form-input
                            id="productCode"
                            v-model="editForm.productCode"
                            placeholder="请输入产品编号"
                            required
                            @blur="checkExists(editForm.productCode)">
                        </b-form-input>
                        <b-form-invalid-feedback :state="checkFormat(this.editForm.productCode)">
                            格式不正确
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="groupProductName" label="名称:" label-for="productName">
                        <b-form-input
                            id="productName"
                            v-model="editForm.productName"
                            placeholder="请输入产品名称"
                            required>
                        </b-form-input>
                        <b-form-invalid-feedback :state="checkEmpty(this.editForm.productName)">
                            请输入产品名称
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="groupCategory" label="分类:" label-for="category">
                        <b-form-select id="category"
                            v-model="editForm.category" 
                            :options="optionsCategory"
                            required>
                        </b-form-select>
                        <b-form-invalid-feedback :state="checkEmpty(this.editForm.category)">
                            请选择分类
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="groupSize" label="大小:" label-for="size">
                        <b-form-select id="size"
                            v-model="editForm.size" 
                            :options="optionsSize"
                            required>
                        </b-form-select>
                        <b-form-invalid-feedback :state="checkEmpty(this.editForm.size)">
                            请选择大小
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="groupDimension" label="尺寸（请正确填写）:" label-for="dimension">
                        <b-form-input
                            id="dimension"
                            v-model="editForm.dimension"
                            placeholder="请输入产品尺寸">
                        </b-form-input>
                        <span style="color:#E6A23C">尺寸统一填写格式: 长：29 * 宽：15.5 * 厚：2 CM</span>
                    </b-form-group>
                    <b-form-group id="groupPrice" label="指导价格（参考价格，单位：元）:" label-for="price">
                        <b-form-input
                            id="price"
                            type= "number"
                            v-model.number="editForm.price"
                            placeholder="请输入价格"
                            @click.native="selectThis($event)">
                        </b-form-input>
                        <b-form-invalid-feedback :state="checkNumber(this.editForm.price)">
                            格式不正确
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback :state="checkZero(this.editForm.price)">
                            价格不能小于零
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="groupPriceDiscount" label="实际出售价格（单位：元）:" label-for="priceSale">
                        <b-form-input
                            id="priceSale"
                            type= "number"
                            v-model.number="editForm.priceSale"
                            placeholder="请输入实际价格"
                            @click.native="selectThis($event)">
                        </b-form-input>
                        <b-form-invalid-feedback :state="checkNumber(this.editForm.priceSale)">
                            格式不正确
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback :state="checkZero(this.editForm.priceSale)">
                            价格不能小于零
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="groupCountStore" label="实际库存数量:" label-for="countStore">
                        <b-form-input
                            id="countStore"
                            type= "number"
                            v-model.number="editForm.countStore"
                            @keyup.native="integer($event)"
                            placeholder="请输入库存数量"
                            @click.native="selectThis($event)">
                        </b-form-input>
                        <b-form-invalid-feedback :state="checkNumber(this.editForm.countStore)">
                            格式不正确
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="groupCountInitialSale" label="初始销售数量:" label-for="count_initial_sale">
                        <b-form-input
                            id="count_initial_sale"
                            type= "number"
                            v-model.number="editForm.countInitialSale"
                            @keyup.native="integer($event)"
                            placeholder="请输入初始销售数量"
                            @click.native="selectThis($event)">
                        </b-form-input>
                        <span style="color:#E6A23C">创建产品时填入促销用的初始销售数量，实际销售后销售数量在初始销售数量上自动递增</span>
                        <b-form-invalid-feedback :state="checkNumber(this.editForm.countInitialSale)">
                            格式不正确
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="groupCountSale" label="实际销售数量:" label-for="count_sale" v-if="editForm.id > 0">
                        <b-form-input
                            id="count_sale"
                            type= "number"
                            v-model.number="editForm.countSale"
                            :disabled="disabled">
                        </b-form-input>
                    </b-form-group>
                    <div style="display:flex;align-items:center;flex-wrap:wrap;margin:20px;0px;">
                        <div style="margin-right:20px;margin-bottom:10px;">
                            <mt-switch v-model="editForm.isOnSale">是否在售</mt-switch>
                        </div>
                        <div style="margin-right:20px;margin-bottom:10px;">
                            <mt-switch v-model="editForm.isHome">是否首页显示</mt-switch>
                        </div>
                        <div style="margin-right:20px;margin-bottom:10px;">
                            <mt-switch v-model="editForm.isNew">是否新款</mt-switch>
                        </div>
                        <div style="margin-right:20px;margin-bottom:10px;">
                            <mt-switch v-model="editForm.isRecommend">是否推荐</mt-switch>
                        </div>
                    </div>
                    <b-form-group id="groupDescription" label="描述:" label-for="description">
                        <b-form-textarea
                            id="description"
                            v-model="editForm.description"
                            rows="6"
                            max-rows="12">
                        </b-form-textarea>
                    </b-form-group>
                    <b-form-group id="groupKeywords" label="关键字:" label-for="keywords">
                        <b-form-textarea
                            id="keywords"
                            v-model="editForm.keywords"
                            rows="3"
                            max-rows="6">
                        </b-form-textarea>
                    </b-form-group>
                    <b-form-group id="groupCreatedTime" label="创建者:" label-for="createUserName" v-if="editForm.id > 0">
                        <b-form-input
                            id="createUserName"
                            v-model="editForm.createUserName"
                            :disabled="disabled">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group id="groupCreatedTime" label="创建时间:" label-for="createdTime" v-if="editForm.id > 0">
                        <b-form-input
                            id="createdTime"
                            v-model="editForm.createdTime"
                            :disabled="disabled">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group id="groupCreatedTime" label="修改者:" label-for="updateUserName" v-if="editForm.id > 0">
                        <b-form-input
                            id="updateUserName"
                            v-model="editForm.updateUserName"
                            :disabled="disabled">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group id="groupUpdatedTime" label="修改时间:" label-for="updatedTime" v-if="editForm.id > 0">
                        <b-form-input
                            id="updatedTime"
                            v-model="editForm.updatedTime"
                            :disabled="disabled">
                        </b-form-input>
                    </b-form-group>
                </b-form>
            </div>
            <template>
                <div class="d-flex bg-senconday text-light align-items-center px-3 py-2">
                    <b-button @click="copyProduct"
                        size="md"
                        style="margin-left:10px;margin-top:5px;">
                        复制
                    </b-button>
                    <b-button @click="saveProduct"
                        variant="primary" size="md"
                        style="margin-left:10px;margin-top:5px;">
                        保存
                    </b-button>
                </div>
            </template>
        </b-sidebar>

        <b-sidebar id="modalUploader" title="上传图片" 
            right shadow
            v-model="showUploador"
            width="85%">
            <div style="margin:10px;">
                <template>
                    <div class="d-flex bg-light text-light align-items-center px-3 py-2">
                        <b-form-file
                            v-model="filesUpload"
                            :state="Boolean(filesUpload)"
                            multiple
                            placeholder="选择文件上传"
                            drop-placeholder="拖动图片到这里"
                            accept=".jpg, .png, .gif, .mp4"
                            ref="file-input">
                        </b-form-file>
                        <b-button @click="uploadConfirm"
                            variant="primary" size="md"
                            style="margin-left:10px;width:70px;">
                            上传
                        </b-button>
                    </div>
                </template>
                <div v-if="pictures.length > 0" 
                    style="width:100%;overflow-y:auto;margin:10px;text-align:center" >
                    <div style="margin-left:10px;margin-bottom:10px;color:#E6A23C;text-align:left;">
                        提示: 您可以鼠标拖动图片进行排序 
                    </div>
                    <div style="margin-left:10px;margin-bottom:10px;text-align:left;">
                        <b-check v-model="checkedAll" @change="togglleCheckedAll">
                            选择/不选择所有图片
                        </b-check>
                    </div>
                    <!-- 不要修改class="pictures"，也不要往此Html元素内增加额外元素，会引起拖动排序产生异常 -->
                    <div style="display:flex;flex-wrap:wrap;justify-content:space-between;" class="pictures"> 
                        <div v-for="picture in pictures" :key="picture.id"
                            style="width:320px;height:auto;margin:10px;position:relative">
                            <b-check v-model="picture.checked"
                                @change="changePictureChecked"
                                style="position:absolute;top:10px;left:10px;">
                            </b-check>
                            <div>
                                <b-img :src="getPictureUrl(picture.picture_url)" 
                                    v-if="picture.picture_url.indexOf('.mp4') < 0"
                                    thumbnail style="width:260px;height:auto;"/>
                                <div v-if="picture.picture_url.indexOf('.mp4') > 0" 
                                    style="width:260px;height:260px;line-height:260px;border:1px solid #ccc">
                                    视频
                                </div>
                            </div>
                            <div>
                                <b-link v-if="picture.is_primary == false || picture.is_primary == null"
                                    @click="setPrimaryPicture(picture.id)"
                                    style="font-size:14px;margin:5px;">
                                    设为主图
                                </b-link>
                                <b-link @click="deletePicture(picture.id)"
                                    style="color:red;font-size:14px;margin:5px;">
                                    删除
                                </b-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div style="width:100%;text-align:center;margin-top:20px;margin-bottom:20px;">没有图片</div>
                </div>
                <div style="text-align:right;margin-right:20px;">
                    <b-button type="button" @click="saveSort" variant="primary">
                        保存排序
                    </b-button>
                     <b-button type="button" @click="deletePictures" variant="danger" style="margin-left:10px;">
                        删除所选图片
                    </b-button>
                </div>
            </div>
        </b-sidebar>
    </b-container>
</template>

<script>
import { 
    Indicator, 
    MessageBox
} from 'mint-ui';

import Sortable from 'sortablejs';

import {
    getOptionsOnSale,
    getOptionsHome,
    getOptionsRecommend,
    getOptionsNew,
    getOptionsCategory,
    getOptionsSize,
    getOptionsSortBy
} from 'api/config';

import { 
    getByProductCode,
    getProductList,
    saveProduct,
    editProduct,
    updateProduct,
    deleteProduct,
    getPictures,
    uploadPicture,
    setPrimaryPicture,
    updatePictures,
    deletePicture,
    deletePictures
} from "api/product";

import { 
    getPicturePath 
} from "api/picture";

export default {
    data() {
        return {
            loading: true,
            disabled: true,
            products: [],
            form: {
                search: null,
                sort_sale: 'True',
                sort_home: null,
                sort_recommend: null,
                sort_new: null,
                category: null,
                size: null,
                sort_by: 'created_time'
            },
            optionsOnSale: [],
            optionsSortBy: [],
            optionsHome: [],
            optionsRecommend: [],
            optionsNew: [],
            optionsCategory: [],
            optionsSize: [],
            showEditor: false,
            editForm: {
                id: 0,
                productName: '',
                productCode: '',
                category: 0,
                size: 0,
                dimension: '',
                price: 0,
                priceSale: 0,
                countStore: 0,
                countSale: 0,
                isOnSale: false,
                isHome: false,
                isNew: false,
                isRecommend: false,
                keywords: '',
                description: '',
                createdTime: '',
                updatedTime: ''
            },
            showUploador: false,
            productCodeUploader: '',
            pictures: [],
            filesUpload: [],
            total: 0,
            currentPage: 1,
            pageSize: 20,
            editingProductCode: '',
            showAllFilter: false,
            finishedCount: 0,
            filesCount: 0,
            checkedAll: false
        };
    },
    filters: {
        
    },
    mounted() {
        this.optionsOnSale = getOptionsOnSale();
        this.optionsHome = getOptionsHome();
        this.optionsRecommend = getOptionsRecommend();
        this.optionsNew = getOptionsNew();
        this.optionsCategory = getOptionsCategory();
        this.optionsSize = getOptionsSize();
        this.optionsSortBy = getOptionsSortBy();
        this.search();
    },
    watch: {
        currentPage: function(newValue, oldValue) {
            this.search();
        }
    },
    methods: { 
        getPictureUrl(pictureUrl) {
            return pictureUrl + "?random=" + Math.floor((Math.random() * 100) + 1);
        },
        togglleCheckedAll() {
            this.pictures.forEach(picture => {
                picture.checked = this.checkedAll;
            });
        },
        selectThis(event) {
            event.target.select();
        },
        checkFormat(value) {
            let reg =  /^[A-Za-z0-9-]+$/;
            return reg.test(value);
        },
        checkEmpty(value) {
            return value != null && value != "";
        },
        checkNumber(value) {
            let reg = /^(\d+|\d+\.\d{1,2})$/;
            return reg.test(value);
        },
        checkExists(value) {
            if(value == "") {
                MessageBox('请输入产品编号', '请输入产品编号');
                return;
            }
            getByProductCode(value, response => {
                if(response.status == 200) {
                    let { data } = response.data;
                    if(data) {
                        MessageBox('编号已存在', '产品编号已存在了');
                    }
                }
            });
        },
        checkZero(value) {
            if(value == '' || value == 0) {
                return false;
            }
        },
        integer(e) {
            var keyNum = window.event ? e.keyCode : e.which;
            if(keyNum == 189 || keyNum == 190 || keyNum == 110 || keyNum == 109) {
                e.target.value = '';
            }
        },
        getCategoryName(category_id) {
            let categories = this.optionsCategory.filter(item => item.value == category_id);
            return categories.length > 0 ? categories[0].text : '';
        },
        getSizeName(size) {
            let sizes = this.optionsSize.filter(item => item.value == size);
            return sizes.length > 0 ? sizes[0].text : '';
        },
        onSubmit() {
            this.search();
        },
        search(event) {
            if(event != undefined && (typeof event) == 'object') {
                event.preventDefault();
            }
            Indicator.open({ text: '加载中...', spinnerType: 'fading-circle' });
            let params = this.getFilter();
            getProductList(params, response => {
                if(response.status == 200) {
                    this.products = response.data.data;
                    if(this.products.length > 0) {
                        this.products.forEach(product => {
                            if(product.picture_url) {
                                product.picture_url = getPicturePath(product.picture_url);
                            }
                        });
                    }
                    this.total = response.data.total;
                }
                Indicator.close();
            });
        },
        getFilter() {
            return {
                "sort_sale": this.form.sort_sale == null ? '' : this.form.sort_sale,
                "sort_home": this.form.sort_home == null ? '' : this.form.sort_home,
                "sort_recommend": this.form.sort_recommend == null ? '' : this.form.sort_recommend,
                "sort_new": this.form.sort_new == null ? '' : this.form.sort_new,
                "category_id": this.form.category == null ? 0 : parseInt(this.form.category),
                "size": this.form.size == null ? 0 : parseInt(this.form.size),
                "search": this.form.search == null ? '' : this.form.search,
                "sort_by": this.form.sort_by,
                "page_size": this.pageSize,
                "current_page": this.currentPage
            };
        },
        onReset() {
            this.form.sort_sale = null;
            this.form.sort_home = null;
            this.form.sort_recommend = null;
            this.form.sort_new = null;
            this.form.category = null;
            this.form.size = null;
            this.form.search = '';
            this.pageSize = 20;
            this.currentPage = 1;
            this.search();
        },
        createProduct() {
            this.onResetForm();
        },
        editProduct(id, product) {
            this.editForm.id = id;
            this.editForm.productCode = product.product_code;
            this.editForm.productName = product.product_name;
            this.editForm.category = product.category_id;
            this.editForm.size = product.size;
            this.editForm.dimension = product.dimension;
            this.editForm.price = product.price;
            this.editForm.priceSale = product.price_sale;
            this.editForm.countStore = product.count_store;
            this.editForm.countInitialSale = product.count_initial_sale;
            this.editForm.countSale = product.count_sale;
            this.editForm.isOnSale = product.is_on_sale;
            this.editForm.isHome = product.is_home;
            this.editForm.isNew = product.is_new;
            this.editForm.isRecommend = product.is_recommend;
            this.editForm.keywords = product.keywords;
            this.editForm.description = product.description;
            this.editForm.createUserName = product.create_username;
            this.editForm.updateUserName = product.update_username;
            this.editForm.createdTime = this.dateFormatString(product.create_time);
            this.editForm.updatedTime = this.dateFormatString(product.update_time);
            this.editingProductCode = product.product_code;
        },
        copyProduct() {
            MessageBox.confirm('确定复制此产品?').then(action => {
                this.editForm.id = 0;
                this.editForm.productCode = '';
                this.editForm.createdTime = '';
                this.editForm.updatedTime = '';
            }).catch(() => {});
        },
        saveProduct() {
            let form = this.$refs['editorForm'];
            let valid = form.checkValidity();
            if(valid) {
                valid = this.checkNumber(this.editForm.price);
            }
            if(valid) {
                let price_sale = parseFloat(this.editForm.priceSale) == 0 ? 
                                     parseFloat(this.editForm.price) : 
                                     parseFloat(this.editForm.priceSale);
                let saveObject = {
                    'product_code': this.editForm.productCode,
                    'product_name': this.editForm.productName,
                    'category_id': parseInt(this.editForm.category),
                    'size': parseInt(this.editForm.size),
                    'dimension': this.editForm.dimension,
                    'price': parseFloat(this.editForm.price),
                    'price_sale': price_sale,
                    'count_store': parseInt(this.editForm.countStore),
                    'count_initial_sale': parseInt(this.editForm.countInitialSale),
                    'is_on_sale': this.editForm.isOnSale,
                    'is_home': this.editForm.isHome,
                    'is_new': this.editForm.isNew,
                    'is_recommend': this.editForm.isRecommend,
                    'keywords': this.editForm.keywords,
                    'description': this.editForm.description
                };
                if(this.editForm.id == 0) {
                    saveProduct(this.editForm.productCode, saveObject, response => {
                        if(response.status == 201) {
                            MessageBox('保存成功', '保存成功');
                            this.search();
                        }
                    });
                } else {
                    editProduct(this.editingProductCode, saveObject, response => {
                        if(response.status == 200) {
                            MessageBox('保存成功', '保存成功');
                            this.search();
                            this.editingProductCode = '';
                        }
                    });
                }
                this.showEditor = false;
            } else {
                MessageBox('保存失败', '保存失败，数据验证失败');
            }
        },
        onResetForm(event) {
            if(event) {
                event.preventDefault();
            }
            this.editForm.id = 0;
            this.editForm.productCode = '';
            this.editForm.productName = '';
            this.editForm.category = null;
            this.editForm.size = null;
            this.editForm.dimension = '';
            this.editForm.price = 0;
            this.editForm.priceSale = 0;
            this.editForm.countStore = 0;
            this.editForm.countInitialSale = 0;
            this.editForm.countSale = 0;
            this.editForm.isOnSale = false;
            this.editForm.isHome = false;
            this.editForm.isNew = false;
            this.editForm.isRecommend = false;
            this.editForm.keywords = '';
            this.editForm.description = '';
            this.editForm.createdTime = '';
            this.editForm.updatedTime = '';
        },
        toggleProduct(method, productCode, isValue) {
            MessageBox.confirm('确定执行此更新操作?').then(action => {
                let saveObject = { 'method': method };
                if(method == 'sale') {
                    saveObject.is_on_sale = !isValue;
                } else if(method == 'home') {
                    saveObject.is_home = !isValue;
                } else if(method == 'recommend') {
                    saveObject.is_recommend = !isValue;
                } else if(method == 'new') {
                    saveObject.is_new = !isValue;
                }
                updateProduct(productCode, saveObject, response => {
                    if(response.status == 200) {
                        MessageBox('保存成功', '保存成功');
                        this.search();
                    }
                });
            }).catch(() => {});
        },
        showUploaded(productCode) {
            this.checkedAll = false;
            this.productCodeUploader = productCode;
            Indicator.open({ text: '加载中...', spinnerType: 'fading-circle' });
            getPictures(productCode, response => {
                if(response.status == 200) {
                    this.pictures = response.data.data;
                    if(this.pictures.length > 0) {
                        this.pictures.forEach(picture => {
                            picture.picture_url = getPicturePath(picture.picture_url);
                            this.$set(picture, "checked", false);
                        });
                    }
                    this.$nextTick(() => {
                        const el = document.querySelectorAll('.pictures')[0];
                        let self = this;
                        Sortable.create(el, {
                            onEnd({ newIndex, oldIndex }) {
                                const targetRow = self.pictures.splice(oldIndex, 1)[0];
                                self.pictures.splice(newIndex, 0, targetRow);
                            }
                        });
                    });
                }
                Indicator.close();
            }, error => {
                setTimeout(() => { Indicator.close(); }, 1000);
            });
            this.showUploador = true;
        },
        uploadConfirm() {
            if(this.filesUpload.length == 0) { 
                MessageBox('上传失败', `请选择上传的文件`);
                return;
            } else {
                this.finishedCount = 0;
                this.filesCount = this.filesUpload.length;
                for(var index = 0; index < this.filesUpload.length; index++) {
                    let fileUpload = this.filesUpload[index];
                    let formData = new FormData();
                    formData.append('file', fileUpload);
                    uploadPicture(this.productCodeUploader, formData, response => {
                        this.finishedCount = this.finishedCount + 1;
                    }, error => {
                        console.log(error);
                        MessageBox('上传失败', `上传图片失败 ${error}`);
                    });
                }
                let _this = this;
                setTimeout(() => {
                    if(_this.finishedCount == _this.filesCount) {
                        MessageBox('上传成功', '所有图片上传成功');
                        _this.showUploaded(this.productCodeUploader);
                        _this.resetUpload();
                    } else {
                        setTimeout(() => {
                            _this.showUploaded(this.productCodeUploader);
                            _this.resetUpload();
                        }, 1000);
                    }
                }, 1000);
            }
        },
        changePictureChecked() {
            this.checkedAll = this.pictures.filter(picture => {
                return picture.checked == true;
            }).length == this.pictures.length;
        },
        setPrimaryPicture(pictureId) {
            setPrimaryPicture(pictureId, response => {
                if(response.status == 200) {
                    MessageBox('保存成功', '保存成功');
                    this.showUploaded(this.productCodeUploader);
                }
            });
        },
        resetUpload() {
            this.$refs['file-input'].reset();
            this.fileUpload = [];
            this.search();
        },
        cancelUpload() {
            this.productCodeUploader = '';
            this.pictures = [];
            this.resetUpload();
            this.showUploador = false;
        },
        deleteProduct(productCode) {
            MessageBox.confirm(`确定删除此产品 ${productCode} ? 请注意此项操作不能回退！`).then(action => {
                deleteProduct(productCode, response => {
                    if(response.status == 200) {
                        MessageBox('删除成功', '删除成功');
                        this.search();
                    }
                });
            }).catch((err) => {
                MessageBox('删除失败', '不能删除此产品，有客户下单，你可以下架此产品');
            });
        },
        deletePicture(pictureId) {
            MessageBox.confirm('确定执行此操作?').then(action => {
                deletePicture(pictureId, response => {
                    if(response.status == 200) {
                        MessageBox('删除成功', '删除成功');
                        this.showUploaded(this.productCodeUploader);
                    }
                });
            }).catch(()=>{});
        },
        deletePictures() {
            if(this.pictures.length == 0) {
                MessageBox('警告', '图片列表为空!');
                return;
            }
            let selectedPictures = this.pictures.filter(picture => { return picture.checked == true; });
            if(selectedPictures.length == 0) {
                MessageBox('警告', '请选择待删除的图片!');
                return;
            }
            let pictureIds = [];
            selectedPictures.forEach(picture => {
                pictureIds.push(picture.id);
            });
            MessageBox.confirm('确定删除选中的图片?').then(action => {
                deletePictures(pictureIds.join(','), response => {
                    if(response.status == 200) {
                        MessageBox('删除成功', '删除图片成功');
                        this.showUploaded(this.productCodeUploader);
                    }
                });
            }).catch(()=>{});
        },
        saveSort() {
            if(this.pictures.length == 0) {
                MessageBox('警告', '图片列表为空!');
                return;
            }
            let saveItems = [];
            this.pictures.forEach((item, index) => {
                saveItems.push(item.id);
            });
            let saveObject = { 'pictures': saveItems };
            updatePictures(saveObject, response => {
                if (response.status == 200) {
                    MessageBox('保存成功', '保存成功');
                    this.showUploaded(this.productCodeUploader);
                } else {
                    MessageBox('保存失败', '保存失败');
                }
            });
        }
    }
};
</script>

<style scoped>
.badge {
    padding: 4px 8px;
}

.product-row {
    display:flex;
    justify-content:center;
}

.product-info {
    display:flex;
    align-items:center;
    justify-content:flex-start;
    margin-bottom:5px;
}

.product-name {
    width:150px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.product-badgets {
    display:inline-block;
    margin-right:10px;
    margin-bottom:5px;
}
</style>